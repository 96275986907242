<template>
  <v-container class="lighten-5 mb-6" fill-height>
    <v-row justify="center">
      <v-col cols="12"
              sm="12"
              md="12">
              <h1 class="title-success message">Merci pour votre participation!</h1>

      </v-col>


    </v-row>
    <v-row justify="center" v-if="!success">
      <v-col cols="8">
              <v-form ref="form"
                      lazy-validation>
                      <v-row justify="center">
                        <v-textarea label="Laissez nous un petit message !"
                                    auto-grow
                                    outlined
                                    rows="3"
                                    row-height="25"
                                    shaped
                                    v-model="message"
                                    class="message"
                        ></v-textarea>
                      </v-row>
                      <v-row justify="center">
                        <vue-recaptcha ref="recaptcha"
                                        sitekey="6LfFI7cdAAAAAGsrDRtsKLNUMCpKe_GaBfEaqJmi"
                                        :load-recaptcha-script="true"
                                        @verify="verifyMethod"
                                        @expired="expiredMethod"
                                        @error="errorMethod">
                          <v-btn  :disabled="!is_valid" color="success"
                                  class="mr-4"
                                  @click="send_message">
                            Envoyer
                          </v-btn>
                        </vue-recaptcha>
                      </v-row>
              </v-form>
      </v-col>
    </v-row>
    <v-row justify="center" v-else>
      <v-col cols="8" align="center">
        <span class="message">Message envoyé !</span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <h2><span style="color: #e25555;">&hearts;</span></h2>
    </v-row>
    <v-row justify="center">
      <v-btn color="gray darken-1" text @click="back">Retour à l'accueil</v-btn>
    </v-row>
    <v-alert type="error" transition="slide-y-transition" :value="alert">
      Oups ! Une erreur est survenue.
    </v-alert>
  </v-container>
</template>

<script>
// import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha'


export default {
  name: 'successpage',
  data () {
    return {
      valid: false,
      message: '',
      success: false,
      alert: false
    }
  },
  components: {
    VueRecaptcha
  },
  mounted () {
    this.$npl.list.payment_success(this.$route.query.session_id).then(() => {
    }).catch((error) => {
      this.show_alert()
      console.log(error)
    })
  },
  methods: {
    back () {
      this.$router.push('/')
    },
    show_alert: function () {
      this.alert = true
      window.setInterval(() => {
        this.alert = false
      }, 3000)
    },
    send_message () {
      this.$refs.recaptcha.execute()
    },
    verifyMethod () {
      this.resetRecaptcha()
      this.$npl.list.send_message(this.$route.query.session_id, {
        'message': this.message
      }).then(() => {
        this.message = ''
        this.success = true
      }).catch((error) => {
        this.show_alert()
        console.log(error)
      })
    },
    errorMethod () {
      this.resetRecaptcha()
      this.show_alert()
    },
    expiredMethod () {
      this.show_alert()
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset()
    },
  },
  computed: {
    is_valid () {
      return this.message.length > 0
    }
  }
}
</script>

<style scoped>
.title-success {
  text-align: center;
}
.message {
  font-size: 1.5rem
}
</style>
