import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import NplAPI from '/src/api/npl'

Vue.config.productionTip = false
Vue.prototype.$npl= new NplAPI({'basePath': process.env.VUE_APP_API_HOST});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
