<template>
  <v-card class="mx-auto my-12"
          max-width="250">
    <v-card-actions>
      <v-btn
        :disabled="amount<0"
        color="lighten-2"
        text
        @click="popup(amount, 3)"
      >
        Participation libre
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EventBus from '../event-bus'
import cst from '../constant'

export default {
  name: 'libre',
  data () {
    return {
      amount: 0
    }
  },
  methods: {
    popup: (_current, _mode) => {
      EventBus.$emit(cst.OPEN_POPUP, {
        'id': null,
        'name': null,
        'price': null,
        'current': _current,
        'img': null,
        'mode': _mode === 0 ? cst.MODE_PARTICIPATION : _mode === 1 ? cst.MODE_OFFER : cst.MODE_LIBRE
      })
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
<style scoped>

</style>
