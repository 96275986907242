const OFFER_EVENT = 'OFFER_EVENT'
const PARTICIPATE_EVENT = 'PARTICIPATE_EVENT'
const FREE_PARTICIPATION_EVENT = 'FREE_PARTICIPATION_EVENT'
const OPEN_POPUP = 'OPEN_POPUP'
const MODE_OFFER = 'MODE_OFFER'
const MODE_PARTICIPATION = 'MODE_PARTICIPATION'
const MODE_LIBRE = 'MODE_LIBRE'

export default {
  OFFER_EVENT: OFFER_EVENT,
  PARTICIPATE_EVENT: PARTICIPATE_EVENT,
  FREE_PARTICIPATION_EVENT: FREE_PARTICIPATION_EVENT,
  OPEN_POPUP: OPEN_POPUP,
  MODE_OFFER: MODE_OFFER,
  MODE_PARTICIPATION: MODE_PARTICIPATION,
  MODE_LIBRE: MODE_LIBRE
}
