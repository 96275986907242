<template>
  <div id="app">
    <v-app>
      <router-view/>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@font-face {
  font-family: "Amatic";
  src: local("Amatic"),
   url(./assets/amatic.ttf) format("truetype");
}

@font-face {
  font-family: "Cassandre Light";
  src: local("Cassandre Light"),
   url(./assets/cassandre_light.ttf) format("truetype");
}
#app {
  margin: 0px;
  padding: 0px;
  font-family: 'Cassandre Light';
  /* background-color: #c45824;
  background-image: linear-gradient(0deg, #c45824 0%, #ffd6c5 100%); */

  /* background-color: #f7ccbe; */
  /* background-image: linear-gradient(0deg, #c45824 0%, #fff5f5 100%); */

}

</style>
