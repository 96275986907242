import axios from 'axios'

class NplAPI {
  constructor(config) {
    this.config = config
    this.list = {
      get: async pk =>
        this.call(`/list/${pk}/products`),
      participate: async (pk, product_id, data) =>
        this.call(`/list/${pk}/products/${product_id}/participation`, {method: 'post', data}),
      buy: async (pk, product_id) =>
        this.call(`/list/${pk}/products/${product_id}/buy`),
      free_participate: async (pk, data) =>
        this.call(`/list/${pk}/participation`, {method: 'post', data}),
      payment_success: async (session_id) =>
        this.call(`/list/session/${session_id}/success`),
      send_message: async (session_id, data) =>
        this.call(`/list/session/${session_id}/message`, {method: 'post', data})
    }
  }

  async call (path, { method = 'get', data, parameters = {} } = {}) {
    const queryParameters = Object
      .keys(parameters)
      .map(key => `${key}=${encodeURIComponent(parameters[key])}`)
      .join('&')
    const url = `${this.config.basePath}${path}/?${queryParameters}`
    const response = await axios.request(url, { method, data })

    return response.data
  }
}

export default NplAPI
