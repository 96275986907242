<template>
  <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <product v-for="item in items" :id="item.id" :name="item.name" :price="item.price" :current="item.current" :key="item.id" :img="item.image"></product>
    </v-layout></v-container>
</template>

<script>
import Product from '@/components/Product'

export default {
  name: 'products',
  props: {
    items: {
      type: Array
    }
  },
  data () {
    return {
    }
  },
  components: {
    Product
  }
}
</script>
<style scoped>
h1, h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
</style>
