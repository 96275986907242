<template>
  <v-card :disabled="!is_enabled(price, current)"
          class="mx-auto my-12 d-flex flex-column"
          width="250"
          >

    <v-img
          class="ma-2"
          contain
          height="150"
          :src="img"
        ></v-img>

    <v-card-title>{{ name | capitalize }}</v-card-title>

    <v-card-text>
      <div class="my-4 subtitle-1">
        {{ (price - current) > 0 ? price - current : price }}€
      </div>
    </v-card-text>

    <!-- <v-divider class="mx-4"></v-divider> -->

    <v-card-actions>
      <!-- <v-spacer></v-spacer> -->
      <v-btn
        color="lighten-2"
        text
        @click="popup(id, name, price, img, current, 0)"
        v-show="price-current>0"
      >
        Participer
      </v-btn>
      <v-btn
        color="lighten-2"
        text
        @click="popup(id, name, price, img, current, 1)"
        v-show="price-current>0"
      >
        Offrir
      </v-btn>

      <v-btn
        color="lighten-2"
        text
        v-show="price-current==0"
      >
        Déjà offert !
      </v-btn>

    </v-card-actions>
    <v-progress-linear :value="progress(price, current)"></v-progress-linear>

  </v-card>
</template>

<script>
import EventBus from '@/event-bus'
import cst from '@/constant'

export default {
  name: 'product',
  props: {
    id: {
      type: Number
    },
    name: {
      type: String
    },
    price: {
      type: Number
    },
    current: {
      typ: Number
    },
    img: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  methods: {
    is_enabled: (total, current) => {
      return current < total
    },
    progress: (total, current) => {
      return current * 100 / total
    },
    popup: (_id, _name, _price, _img, _current, _mode) => {
      EventBus.$emit(cst.OPEN_POPUP, {
        'id': _id,
        'name': _name,
        'price': _price,
        'current': _current,
        'img': _img,
        'mode': _mode === 0 ? cst.MODE_PARTICIPATION : cst.MODE_OFFER
      })
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
<style scoped>
.img-product {}

  /* your css */

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
