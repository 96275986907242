<template>
  <v-dialog v-model="dialog" max-width="290" @input="v => v || close">
   <v-card v-show="mode == 'MODE_OFFER'" style="padding-top: 9px;">
     <v-img
       v-if="img"
        contain
        height="150"
        :src="img"
      ></v-img>
     <v-card-text class="pa-5 text-custom">Vous souhaitez offrir le cadeau <span style="font-weight: bold;">{{ name }}</span>, en cliquant sur <span style="font-weight: bold;">Offrir</span> vous serez redirigé vers la page de paiement.</v-card-text>
     <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="dialog=false">Annuler</v-btn>
      <v-btn color="green darken-1" text @click="buy">Offrir</v-btn>
     </v-card-actions>
   </v-card>
    <v-card v-if="mode == 'MODE_PARTICIPATION'" style="padding-top: 9px;">
     <v-img
        contain
        height="150"
        :src="img"
      ></v-img>
      <v-card-text class="pa-5 text-custom">
        Vous souhaitez participer pour le cadeau <span style="font-weight: bold;">{{ name }}</span>, veuillez rentrer votre participation et cliquer sur <span style="font-weight: bold;">Participer</span> pour être redirigé vers la page de paiement.
        <v-text-field
          v-model="participation"
          suffix="€"
          class="text-custom"
          style="margin-top: 10px; max-width: 130px;"
          label="Participation"
          type="number"
          min="1"
          :max="price-current"
          hide-details="auto"
        ></v-text-field>

      </v-card-text>
     <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="dialog=false">Annuler</v-btn>
      <v-btn color="green darken-1" text :disabled="disabled" @click="participate">Participer</v-btn>
     </v-card-actions>
   </v-card>

    <v-card v-if="mode == 'MODE_LIBRE'" style="padding-top: 9px;">
      <v-card-text class="pa-5 text-custom">
        Vous souhaitez effectuer une participation libre, veuillez rentrer votre participation et cliquer sur <span style="font-weight: bold;">Participer</span> pour être redirigé vers la page de paiement.
        <v-text-field
          v-model="libre_amount"
          suffix="€"
          style="margin-top: 10px; max-width: 130px;"
          label="Participation"
          type="number"
          min="1"
          hide-details="auto"
        ></v-text-field>

      </v-card-text>
     <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="dialog=false">Annuler</v-btn>
      <v-btn color="green darken-1" text :disabled="libre_amount<1" @click="libre">Participer</v-btn>
     </v-card-actions>
   </v-card>

  </v-dialog>
</template>
<script>
import EventBus from '../event-bus'
import cst from '../constant'

export default {
  name: 'popup-buy',
  data () {
    return {
      stripeAPIToken: 'pk_test_YOUR_RANDOM_KEY',
      dialog: false,
      id: null,
      name: null,
      price: null,
      current: null,
      img: null,
      mode: null,
      participation: 1,
      libre_amount: 1,
      stripe: null
    }
  },
  mounted () {
    EventBus.$on(cst.OPEN_POPUP, (payLoad) => {
      this.id = payLoad.id
      this.name = payLoad.name
      this.price = payLoad.price
      this.current = payLoad.current
      this.img = payLoad.img
      this.mode = payLoad.mode
      this.dialog = true
    })
  },
  methods: {
    close: () => {
      this.dialog = false
      this.id = null
      this.name = null
      this.price = null
      this.current = null
      this.img = null
      this.mode = null
    },
    buy () {
      this.$npl.list.buy(process.env.VUE_APP_MAIN_LIST, this.id).then((response) => {
        // window.open(response.url)
        window.location = response.url
      })
    },
    participate () {
      this.$npl.list.participate(process.env.VUE_APP_MAIN_LIST, this.id, {
        'amount': this.participation
      }).then((response) => {
        // window.open(response.url)
        window.location = response.url
      })
    },
    libre () {
      this.$npl.list.free_participate(process.env.VUE_APP_MAIN_LIST, {
        'amount': this.libre_amount
      }).then((response) => {
        // window.open(response.url)
        window.location = response.url
      })
    }
  },
  beforeDestroy: () => {
    EventBus.$off(cst.OPEN_POPUP)
  },
  computed: {
    isopen: {
      get () { return this.dialog },
      set (value) { this.$emit('update:dialog', value) }
    },
    disabled () {
      return this.participation < 1 || this.participation > this.price - this.current
    }
  }
}
</script>
<style scoped>
.text-custom {
  font-size: 1.2rem;
}
</style>
