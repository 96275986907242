<template>
  <v-container class="lighten-5 mb-6" fill-height>
    <v-row style="min-height: 100vh;">
      <v-row justify="center">
        <h1 class="main-title">Liste de mariage</h1>
      </v-row>

      <v-row justify="center" class="pa-10">
        <p class="font-weight-light">
            Chers amis, chère famille,
        </p>

        <p class="font-weight-light text-center">
            Nous sommes très heureux que vous nous entouriez le jour de notre mariage.
            A nos tendres familles aimantes, à nos amis de longue date ou aux rencontres récentes et si riches, quelle chance de vous avoir dans nos vies !
            Sur cette page se trouve l'ensemble de nos rêves matérialistes, qui ne sont en rien indispensables à notre bonheur (mais qui y contribueront peut-être un peu :-)).
        </p>
        <p class="font-weight-light">
          Merci beaucoup,
        </p>
      </v-row>

      <v-row justify="center">
        <h2>Gabriel <span style="color: #e25555;">&hearts;</span> Louise</h2>
      </v-row>

    </v-row>

    <v-row justify="center">
      <libre></libre>
    </v-row>
    <v-row justify="center">
      <products :items="data"></products>
    </v-row>
    <v-row justify="center">
      <h3 style="color: gray;">contact@notrepetiteliste.fr</h3>
    </v-row>
    <popup-buy></popup-buy>
  </v-container>
</template>

<script>
import Products from '@/components/Products'
import PopupBuy from '@/components/PopupBuy'
import Libre from '@/components/Libre'

export default {
  name: 'home',
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this.$npl.list.get(process.env.VUE_APP_MAIN_LIST).then((res) => {
      this.data = res
    })
  },
  components: {
    Products,
    PopupBuy,
    Libre
  }
}
</script>

<style scoped>

.main-title {
  font-size: 6rem;
  font-family: 'Amatic';
}

p {
  font-size: 1.5rem
}

h1, h2 {
  width: 100%;
  padding: 20px;
  text-align: center;
}

h2 {
  color: gray;
}
h3 {
  color: gray;
}

</style>
